import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { Box } from '../Core'
import { globalSearch } from '../../store/actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import { Row } from 'react-bootstrap'
import Link from '../Link'
import { useTranslation } from 'react-i18next'
import theme from '../../utils/theme'

const BoxInput = styled(Box)`
  display: flex;
  align-items: center;
  button {
    font-size: 19px;
    color: rgba(255, 255, 255, 0.7);
    height: 100%;
    padding-top: 3px;
    padding-bottom: 6px;
    margin-top: 0px;
    cursor: pointer;
    background: transparent;
    border: none;
    &:focus {
      outline: none;
    }
  }
  input {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -0.2px;
    line-height: 26px;
    border: 0;
    background: transparent;
    padding-left: 0.2rem;
    &:focus {
      box-shadow: none;
      background: transparent;
    }
  }
`

const ListItem = styled.li`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 18px !important;
  padding-right: 24px !important;
  color: ${props =>
    props.userDarkMode
      ? theme.colors.lightShade
      : theme.colors.darkShade} !important;
`

const InputSearch = props => {
  const dispatch = useDispatch()
  const [searchTriggered, setSearchTriggered] = useState(false)
  const [searchedInput, setSearchedInput] = useState('')
  const searchResultExchs = useSelector(state => state.user.searchResultExchs)
  const searchResultMkts = useSelector(state => state.user.searchResultMkts)
  const searchResultCurrs = useSelector(state => state.user.searchResultCurrs)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userSettings = useSelector(state => state.user.user_settings)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false
  const searchListRef = useRef()
  const [showResults, setShowResults] = useState(false)

  const { i18n } = useTranslation()

  const metaData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            languages
          }
        }
      }
    `
  )
  const langs = metaData.site.siteMetadata.languages
  let i18nLang
  let lang

  if (typeof i18n.language !== 'undefined') {
    i18nLang = i18n.language.includes('-')
      ? i18n.language.split('-')[0]
      : i18n.language
  }

  lang =
    typeof langs.find(lang => lang === i18nLang) !== 'undefined'
      ? langs.find(lang => lang === i18nLang)
      : 'en'

  useEffect(() => {
    if (searchedInput.length > 1) {
      const timer = setTimeout(async () => {
        await dispatch(globalSearch(searchedInput))
        await setSearchTriggered(true)
        await setShowResults(true)
      }, 1000)
      return () => clearTimeout(timer)
    }
    if (searchedInput.length < 1) {
      setShowResults(false)
    }
  }, [searchedInput, dispatch])

  // const handleKeypress = e => {
  //   if (e.key === 'Enter') {
  //     dispatch(globalSearch(searchedInput))
  //     setSearchTriggered(true)
  //     setShowResults(true)
  //   }
  // }
  // const handleSearch = () => {
  //   setSearchTriggered(true)
  //   dispatch(globalSearch(searchedInput))
  //   setShowResults(true)
  // }

  const handleInput = input => {
    setSearchedInput(input)
  }

  return (
    <>
      <BoxInput {...props}>
        <div>
          <Row style={{ width: 180, marginLeft: 10 }}>
            {/* <button onClick={() => handleSearch()}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.3355 11.7333H12.4885L12.1941 11.4411C13.274 10.1875 13.8675 8.58782 13.8667 6.93334C13.8667 5.56205 13.46 4.22156 12.6982 3.08138C11.9363 1.9412 10.8535 1.05254 9.58661 0.527772C8.31971 0.00300411 6.92565 -0.134299 5.58071 0.133225C4.23578 0.400749 3.00037 1.06109 2.03073 2.03073C1.06109 3.00037 0.400749 4.23578 0.133225 5.58071C-0.134299 6.92565 0.00300411 8.31971 0.527772 9.58661C1.05254 10.8535 1.9412 11.9363 3.08138 12.6982C4.22156 13.46 5.56205 13.8667 6.93334 13.8667C8.58718 13.8678 10.1865 13.2751 11.44 12.1963L11.7344 12.4885V13.3333L17.0656 18.6571L18.656 17.0667L13.3355 11.7333ZM6.93334 11.7333C6.30299 11.7333 5.67882 11.6092 5.09646 11.368C4.51409 11.1267 3.98495 10.7732 3.53922 10.3274C3.0935 9.88173 2.73994 9.35258 2.49871 8.77022C2.25749 8.18785 2.13334 7.56368 2.13334 6.93334C2.13334 6.30299 2.25749 5.67882 2.49871 5.09646C2.73994 4.51409 3.0935 3.98495 3.53922 3.53922C3.98495 3.0935 4.51409 2.73994 5.09646 2.49871C5.67882 2.25749 6.30299 2.13334 6.93334 2.13334C8.20638 2.13334 9.42727 2.63905 10.3274 3.53922C11.2276 4.4394 11.7333 5.6603 11.7333 6.93334C11.7333 8.20638 11.2276 9.42727 10.3274 10.3274C9.42727 11.2276 8.20638 11.7333 6.93334 11.7333Z"
                  fill={
                    userDarkMode
                      ? '#FAFAFA'
                      : theme.colors.darkShade
                  }
                  fillOpacity="0.6"
                />
              </svg>
            </button> */}
            <input
              style={{
                width: 130,
                color: userDarkMode ? '#FAFAFA' : theme.colors.darkShade,
              }}
              value={searchedInput}
              onChange={e => handleInput(e.target.value)}
              type="text"
              className="form-control"
              placeholder="Search"
              // onKeyPress={e => handleKeypress(e)}
            />
          </Row>

          {searchTriggered === true ? (
            <div
              key={Math.random()}
              tabIndex={0}
              role="button"
              onKeyDown={() => setShowResults(false)}
              onClick={() => setShowResults(false)}
              ref={searchListRef}
              style={{
                backgroundColor: userDarkMode
                  ? theme.colors.dark
                  : theme.colors.light,
                position: 'absolute',
                borderRadius: '0 0 10px 10px',
                boxShadow: '0 52px 54px rgba(65, 62, 101, 0.3)',
                padding: 10,
                borderTopColor: '#3377C6',
                borderTopStyle: 'solid',
                borderTopWidth: 4,
                overflow: 'scroll',
                maxHeight: 500,
                display: showResults ? 'block' : 'none',
              }}
            >
              <ul key={Math.random()} style={{ fontSize: 16 }}>
                {searchResultExchs.length > 0 ? (
                  <ListItem
                    key="exchanges"
                    userDarkMode={userDarkMode}
                    style={{ paddingBottom: 0, fontSize: 13 }}
                  >
                    Exchanges
                  </ListItem>
                ) : null}

                {searchResultExchs.length > 0
                  ? searchResultExchs.map(exch => {
                      let exchLogo = (
                        <img
                          width="20"
                          height="20"
                          style={{ marginRight: 8, marginTop: -5 }}
                          src={
                            process.env.WWW_URL +
                            `/assets/img/exchange/${exch.code}-icon.png`
                          }
                          alt={`${exch.name} Logo`}
                          onError={e => {
                            e.target.onerror = null
                            e.target.src =
                              process.env.WWW_URL +
                              `/assets/img/currency/empty.png`
                          }}
                        />
                      )
                      // console.log('MAP', exch)
                      return (
                        <div key={Math.random()}>
                          <ListItem
                            userDarkMode={userDarkMode}
                            key={exch.exchCode}
                          >
                            <Link to={`/${lang}/exchanges/${exch.slug}/`}>
                              {exchLogo}
                              <span
                                style={{
                                  color: userDarkMode
                                    ? theme.colors.light
                                    : theme.colors.dark,
                                }}
                              >
                                {exch.name}
                              </span>
                            </Link>
                          </ListItem>
                        </div>
                      )
                    })
                  : null}
                {searchResultMkts.length > 0 ? (
                  <ListItem
                    key="markets"
                    userDarkMode={userDarkMode}
                    style={{ paddingBottom: 0, fontSize: 13 }}
                  >
                    Markets
                  </ListItem>
                ) : null}

                {searchResultMkts.length > 0
                  ? searchResultMkts.map(mkt => {
                      let exchLogo = (
                        <img
                          width="20"
                          height="20"
                          style={{ marginRight: 8, marginTop: -5 }}
                          src={
                            process.env.WWW_URL +
                            `/assets/img/exchange/${mkt.exchCode}-icon.png`
                          }
                          alt={`${mkt.exchName} Logo`}
                          onError={e => {
                            e.target.onerror = null
                            e.target.src =
                              process.env.WWW_URL +
                              `/assets/img/currency/empty.png`
                          }}
                        />
                      )
                      return (
                        <div key={mkt.marketName + mkt.exchCode}>
                          <ListItem
                            userDarkMode={userDarkMode}
                            key={Math.random()}
                          >
                            <Link
                              to={`/${lang}/markets/${
                                mkt.exchSlug
                              }/${mkt.marketName.toLowerCase()}/`}
                            >
                              {exchLogo}
                              <span
                                style={{
                                  color: userDarkMode
                                    ? theme.colors.light
                                    : theme.colors.dark,
                                }}
                              >
                                {mkt.displayName}
                              </span>
                            </Link>
                          </ListItem>
                        </div>
                      )
                    })
                  : null}
                {searchResultCurrs.length > 0 ? (
                  <ListItem
                    key="currencies"
                    userDarkMode={userDarkMode}
                    style={{ paddingBottom: 0, fontSize: 13 }}
                  >
                    Currencies
                  </ListItem>
                ) : null}
                {searchResultCurrs.length > 0
                  ? searchResultCurrs.map(curr => {
                      let currencyLogo = (
                        <img
                          width="20"
                          height="20"
                          style={{ marginRight: 8, marginTop: -5 }}
                          src={
                            process.env.WWW_URL +
                            `/assets/img/currency/${curr.code}.png`
                          }
                          alt={`${curr.name} Logo`}
                          onError={e => {
                            e.target.onerror = null
                            e.target.src =
                              process.env.WWW_URL +
                              `/assets/img/currency/empty.png`
                          }}
                        />
                      )
                      // console.log('MAP', curr)
                      return (
                        <div key={curr.name}>
                          <ListItem
                            userDarkMode={userDarkMode}
                            key={Math.random()}
                          >
                            <Link to={`/${lang}/cryptocurrencies/${curr.slug}/`}>
                              {currencyLogo}
                              <span
                                style={{
                                  color: userDarkMode
                                    ? theme.colors.light
                                    : theme.colors.dark,
                                  marginTop: 2,
                                }}
                              >
                                {curr.name}
                              </span>
                            </Link>
                          </ListItem>
                        </div>
                      )
                    })
                  : null}
              </ul>
            </div>
          ) : null}
        </div>
      </BoxInput>
    </>
  )
}
export default InputSearch
